.adsk-viewing-viewer #toolbar-explodeTool {
  display: none !important;
}
.adsk-viewing-viewer #toolbar-settingsTool {
  display: none !important;
}
.adsk-viewing-viewer #toolbar-propertiesTool {
  display: none !important;
}
.adsk-viewing-viewer #toolbar-modelStructureTool {
  display: none !important;
}
.adsk-viewing-viewer #toolbar-firstPersonTool {
  display: none !important;
}
.adsk-viewing-viewer #toolbar-cameraSubmenuTool {
  display: none !important;
}
